import React, { useState, useEffect } from 'react';
import CassettePage from './CassettePage';
import ResultsPage from './ResultsPage';
import PasswordPage from './PasswordPage';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentPage, setCurrentPage] = useState('cassette');
  const [initialData, setInitialData] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [isError, setIsError] = useState(false);

  const handleSubmit = (data) => {
    setIsError(false); // Reset error state
    setInitialData(data);
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentPage('results');
      setIsTransitioning(false);
    }, 2000);
  };

  const handleBack = () => {
    setIsTransitioning(true);
    setInitialData(null);
    setIsError(false);
    setTimeout(() => {
      setCurrentPage('cassette');
      setIsTransitioning(false);
    }, 500);
  };

  // Handle background image loading
  useEffect(() => {
    const img = new Image();
    const timeoutId = setTimeout(() => {
      img.src = currentPage === 'results' ? '/background2.jpg' : '/background.jpg';
      img.onload = () => setBackgroundImage(img.src);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [currentPage]);

  if (!isAuthenticated) {
    return (
      <div className="app-container">
        <PasswordPage onPasswordSuccess={() => setIsAuthenticated(true)} />
      </div>
    );
  }

  return (
    <div
      className={`App ${isTransitioning ? 'transitioning' : ''}`}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {currentPage === 'cassette' && (
        <CassettePage
          onSubmit={handleSubmit}
          isTransitioning={isTransitioning}
          isError={isError}
          setIsError={setIsError}
        />
      )}
      
      {currentPage === 'results' && (
        <ResultsPage
          initialData={initialData}
          onBack={handleBack}
        />
      )}
      
      <div className="vhs-overlay"></div>
      <div className="noise"></div>
    </div>
  );
}

export default App;
