import React, { useState, useEffect, useRef } from 'react';

const FilterSelector = ({ onChange, style, initialFilter = 'upcoming' }) => {
  const filters = [
    { id: 'uncharted', label: 'Uncharted', streamThreshold: 0 },
    { id: 'upcoming', label: 'Upcoming', streamThreshold: 50000 },
    { id: 'popular', label: 'Popular', streamThreshold: 1000000 }
  ];
  
  const getFilterIndex = (filterId) => filters.findIndex(f => f.id === filterId);
  const initialIndex = getFilterIndex(initialFilter);
  
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const [floatingSelectedIndex, setFloatingSelectedIndex] = useState(initialIndex);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [offset, setOffset] = useState(0);
  const lastIndexRef = useRef(selectedIndex);
  const accumulatedScrollRef = useRef(0);
  
  const ITEM_WIDTH = 120;
  const ITEM_HEIGHT = 40;
  const VISIBLE_ITEMS = 3;
  const SCROLL_SENSITIVITY = 0.002;

  const setIndexWithinBounds = (index, shouldSnap = true) => {
    if (shouldSnap) {
      const floatingBoundedIndex = Math.max(0, Math.min(filters.length - 1, index));
      setFloatingSelectedIndex(floatingBoundedIndex);
      const roundedIndex = Math.round(index);
      const boundedIndex = Math.max(0, Math.min(filters.length - 1, roundedIndex));
      setSelectedIndex(boundedIndex);
      setOffset(0);
      onChange(filters[boundedIndex]);
    } else {
      const boundedIndex = Math.max(0, Math.min(filters.length - 1, index));
      setFloatingSelectedIndex(boundedIndex);
      setSelectedIndex(Math.floor(boundedIndex));
      setOffset(boundedIndex % 1);
    }
  };

  useEffect(() => {
    onChange(filters[initialIndex]);
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
    lastIndexRef.current = selectedIndex + offset;
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    
    const deltaX = e.clientX - startX;
    const indexDelta = deltaX / ITEM_WIDTH;
    const newIndex = lastIndexRef.current - indexDelta;
    setIndexWithinBounds(newIndex, false);
  };

  const handleMouseUp = (e) => {
    if (!isDragging) return;
    setIsDragging(false);
    
    const deltaX = e.clientX - startX;
    const indexDelta = deltaX / ITEM_WIDTH;
    const newIndex = lastIndexRef.current - indexDelta;
    setIndexWithinBounds(newIndex, true);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].clientX);
    lastIndexRef.current = selectedIndex + offset;
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    
    const deltaX = e.touches[0].clientX - startX;
    const indexDelta = deltaX / ITEM_WIDTH;
    const newIndex = lastIndexRef.current - indexDelta;
    setIndexWithinBounds(newIndex, false);
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;
    setIsDragging(false);
    setIndexWithinBounds(selectedIndex + offset, true);
  };
    
    const handleWheel = (e) => {
      e.preventDefault();
        console.log(e.deltaX);
      
      // Accumulate scroll movement
      const move = e.deltaX * SCROLL_SENSITIVITY;
      accumulatedScrollRef.current += move;
        
        if (Math.abs(move) > 0.5) {
            const deltaX = move;
            const indexDelta = deltaX / ITEM_HEIGHT;
            const newIndex = lastIndexRef.current - indexDelta;
            setIndexWithinBounds(newIndex, false);
        }
        else {
            
            // Get the whole number of units moved
            let unitsToMove = 0;
            if (accumulatedScrollRef.current > 0) unitsToMove = Math.trunc(Math.sqrt(accumulatedScrollRef.current));
            else unitsToMove = Math.trunc(-Math.sqrt(-accumulatedScrollRef.current));
            //console.log(unitsToMove, accumulatedScrollRef.current, e.deltaY);
            if (unitsToMove !== 0 && Math.abs(move) > 0.025) {
                // Keep the remainder
                accumulatedScrollRef.current = 0;
                
                // Calculate new position
                const currentPosition = selectedIndex + offset;
                const newPosition = currentPosition + unitsToMove;
                
                // Update position
                setIndexWithinBounds(newPosition, true);
            }
        }
    };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const baseStyles = {
    width: '300px',
    height: `${ITEM_HEIGHT}px`,
    overflow: 'hidden',
    position: 'relative',
    background: 'linear-gradient(145deg, #353535, #1b1b1b)',
    borderRadius: '8px',
    boxShadow: 'inset 0 2px 5px rgba(0,0,0,0.4), inset 0 -2px 5px rgba(120,120,120,0.4)',
    cursor: 'grab'
  };

  return (
    <div className="filter-selector" style={{ ...baseStyles, ...style }}>
      <div
        className="filter-gradient-left"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          width: ITEM_WIDTH,
          background: 'linear-gradient(to right, #1a1a1a 5%, transparent)',
          zIndex: 2,
          pointerEvents: 'none'
        }}
      />
      <div
        className="filter-gradient-right"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          width: ITEM_WIDTH,
          background: 'linear-gradient(to left, #1a1a1a 5%, transparent)',
          zIndex: 2,
          pointerEvents: 'none'
        }}
      />
      <div
        className="filter-selection-indicator"
        style={{
          position: 'absolute',
          left: '50%',
          top: '0',
          bottom: '0',
          width: ITEM_WIDTH,
          transform: 'translateX(-50%)',
          background: 'linear-gradient(90deg, rgba(255, 0, 0,0.1) 0%, rgba(255, 0, 0,0.2) 50%, rgba(255, 0, 0,0.1) 100%)',
          borderLeft: '1px solid rgba(255, 255, 255, 0.3)',
          borderRight: '1px solid rgba(255, 255, 255, 0.3)',
          boxShadow: '0 0 15px rgba(216, 201, 155, 0.1)',
          zIndex: 1,
          pointerEvents: 'none'
        }}
      />
      <div
        className="filter-items"
        style={{
          position: 'absolute',
          top: 0,
          left: '50%',
          display: 'flex',
          transform: `translateX(-50%) translateX(${(-selectedIndex - offset + 1) * ITEM_WIDTH}px)`,
          transition: isDragging ? 'none' : 'transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)'
        }}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onWheel={handleWheel}
      >
        {filters.map((filter, index) => {
          const distance = Math.abs(index - selectedIndex - offset);
          return (
            <div
              key={filter.id}
              className="filter-item"
              style={{
                width: ITEM_WIDTH,
                height: ITEM_HEIGHT,
                lineHeight: `${ITEM_HEIGHT}px`,
                textAlign: 'center',
                color: 'rgb(216, 201, 155)',
                opacity: Math.max(0.2, 1 - distance * 0.15),
                fontSize: '14px',
                fontFamily: '"DIN Condensed", "Roboto Condensed", "Arial Narrow", sans-serif',
                textShadow: distance < 1 ? '0 0 10px rgba(216, 201, 155, 0.3)' : 'none',
                transform: `scale(${1.2 - Math.abs(floatingSelectedIndex - index) * 0.1})`,
                transition: isDragging ? 'none' : 'all 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)',
                userSelect: 'none',
                whiteSpace: 'nowrap'
              }}
            >
              {filter.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterSelector;
