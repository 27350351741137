import React, { useState, useEffect, useRef } from 'react';
import { Play, Pause, ArrowLeft, Loader, Search } from 'lucide-react';
import FilterSelector from './FilterSelector';
import './ResultsPage.css';

const DEFAULT_FILTER = { id: 'uncharted', streamThreshold: 0 };
const MIN_SONGS_REQUIRED = 1;
const ENGLISH_ISRC_COUNTRIES = ['GB', 'US', 'CA', 'AU', 'NZ'];
const BACKEND = "https://emergesound.ai/api";

const LoadingTransition = ({ onVideoEnd }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.4;
    }
  }, []);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  };

  return (
    <div
      className="video-container"
      style={{
        opacity: isVideoPlaying ? 1 : 0,
        transition: 'opacity 0.3s ease-in',
        visibility: isVideoPlaying ? 'visible' : 'hidden'
      }}
    >
      <video
        ref={videoRef}
        src="/background_video.mp4"
        autoPlay
        onEnded={onVideoEnd}
        onLoadedData={handleVideoLoad}
        onPlay={handleVideoPlay}
        playsInline
        muted
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />
      <div className="vhs-overlay"></div>
      <div className="noise"></div>
    </div>
  );
};

const FloatingPlayer = ({ currentSong }) => {
  if (!currentSong) return null;

  // Convert Apple Music ID to format needed for iframe
  const songId = currentSong.id;
  const embedUrl = `https://embed.music.apple.com/us/song/${songId}`;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-black/90 border-t border-gray-800 backdrop-blur-lg">
      <div className="max-w-7xl mx-auto px-4 py-3 flex items-center gap-4">
        <div className="flex items-center gap-4 flex-1">
          <img
            src={currentSong.artwork_url}
            alt={currentSong.title}
            className="w-16 h-16 rounded-md"
          />
          <div className="flex-1 min-w-0">
            <div className="text-white truncate font-medium">{currentSong.title}</div>
            <div className="text-gray-400 truncate">{currentSong.artist}</div>
          </div>
        </div>
        
        <div className="w-96 h-16 relative">
          <iframe
            title="Apple Music Player"
            allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
            frameBorder="0"
            height="64"
            className="w-full"
            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
            src={embedUrl}
          />
        </div>
      </div>
    </div>
  );
};


const ResultsPage = ({ initialData, onBack }) => {
  const [songs, setSongs] = useState([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [audio, setAudio] = useState(null);
  const [loadingSong, setLoadingSong] = useState(null);
  const [displayedSongs, setDisplayedSongs] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(DEFAULT_FILTER);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [englishOnly, setEnglishOnly] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showVideo, setShowVideo] = useState(true);
  const [hasMinSongs, setHasMinSongs] = useState(false);
  const streamRef = useRef(null);
  const videoRef = useRef(null);
  const isMounted = useRef(true);
  const loadingAudioRef = useRef(null);
  const [currentReference, setCurrentReference] = useState(null);
    
    useEffect(() => {
      setCurrentReference(initialData?.song || null);
    }, [initialData]);

  const handleVideoEnd = () => {
    if (!hasMinSongs) {
      setShowVideo(false);
    }
  };

/*  const filterSongs = (songs, filter) => {
    if (!Array.isArray(songs) || !filter?.id) {
      return [];
    }

    const thresholds = {
      'uncharted': 0,
      'upcoming': 500000,
      'popular': 5000000
    };
    
    const threshold = thresholds[filter.id] || thresholds.popular;
    let filtered = songs;
    
    if (englishOnly) {
      filtered = filtered.filter(song => {
        const countryCode = song.id?.slice(0, 2);
        return ENGLISH_ISRC_COUNTRIES.includes(countryCode);
      });
    }
    
    if (filter.id != 'uncharted') filtered = filtered.filter(song => song.stream_count >= threshold);
    
    return filtered.sort((a, b) => (b.score || 0) - (a.score || 0));
  };*/

const filterSongs = (songs, filter) => {
  if (!Array.isArray(songs) || !filter?.id) {
    return [];
  }

  const thresholds = {
    'uncharted': 0,
    'upcoming': 500000,
    'popular': 5000000
  };
  
  const threshold = thresholds[filter.id] || thresholds.popular;
  let filtered = songs;
  
  // Remove reference track and duplicates
  const seen = new Set();
  filtered = filtered.filter(song => {
    // Create a unique key for each song using title and artist
    const songKey = `${song.title.toLowerCase()}-${song.artist.toLowerCase()}`;
    
    // Skip if this is the reference track
    if (currentReference && 
        song.title.toLowerCase() === currentReference.title.toLowerCase() && 
        song.artist.toLowerCase() === currentReference.artist.toLowerCase()) {
      return false;
    }
    
    // Skip if we've seen this song before
    if (seen.has(songKey)) {
      return false;
    }
    
    // Add to seen set and keep this song
    seen.add(songKey);
    return true;
  });
  
  // Apply English filter if enabled
  if (englishOnly) {
    filtered = filtered.filter(song => {
      const countryCode = song.id?.slice(0, 2);
      return ENGLISH_ISRC_COUNTRIES.includes(countryCode);
    });
  }
  
  // Apply stream count threshold
  if (filter.id != 'uncharted') {
    filtered = filtered.filter(song => song.stream_count >= threshold);
  }
  
  // Sort by score
  return filtered.sort((a, b) => (b.score || 0) - (a.score || 0));
};

  useEffect(() => {
    const controller = new AbortController();
    streamRef.current = controller;
    setHasMinSongs(false);

    const fetchSimilarSongs = async () => {
      if (!initialData?.song && !initialData?.file) {
        console.error('No initial data provided');
        return;
      }

      try {
        const requestBody = {
          song_id: initialData.song.id,
          isrc: initialData.song.isrc,
          start_year: initialData.startYear,
          end_year: initialData.endYear,
        };

        console.log('Starting fetch with body:', requestBody);
              
        const response = await fetch(`${BACKEND}/similar`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/x-ndjson'
          },
          body: JSON.stringify(requestBody),
          signal: controller.signal
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch songs: ${response.status}`);
        }

        console.log('Stream connected, beginning to read data');

        const reader = response.body.getReader();
        let buffer = '';
        const decoder = new TextDecoder();

        while (true) {
          const { value, done } = await reader.read();
          
          if (done) {
            console.log('Stream complete');
            break;
          }

          buffer += decoder.decode(value, { stream: true });
          const lines = buffer.split('\n');
          buffer = lines.pop() || '';

          const newSongs = [];
          for (const line of lines) {
            if (!line.trim()) continue;
            
            try {
              const song = JSON.parse(line);
              //console.log('Received song:', song.title, 'by', song.artist);
              newSongs.push(song);
            } catch (error) {
              console.error('Error parsing song:', error);
            }
          }
              
          console.log("NEWASONGS: " + newSongs.length + " " + (!hasMinSongs));
            
          if (newSongs.length > 0) {
            setSongs(prevSongs => {
              const updatedSongs = [...prevSongs, ...newSongs];
              if (!hasMinSongs && updatedSongs.length >= MIN_SONGS_REQUIRED) {
                setHasMinSongs(true);
                setShowVideo(false);
              }
              return updatedSongs;
            });
          }
        }

      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Stream aborted');
        } else {
          console.error('Failed to fetch similar songs:', error);
          setIsError(true);
          setErrorMessage(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (initialData) {
      console.log('Starting fetch for new initial data:', initialData);
      fetchSimilarSongs();
    }

    return () => {
      if (streamRef.current) {
        streamRef.current.abort();
      }
    };
  }, [initialData]);
  
  useEffect(() => {
    setShowVideo(true);
  }, [initialData]);
  
  useEffect(() => {
    setSongs([]);
    setDisplayedSongs([]);
    setFilteredSongs([]);
    setHasMinSongs(false);
    setIsLoading(true);
    setIsError(false);
    setErrorMessage('');
    
    if (audio) {
      audio.pause();
      setAudio(null);
      setCurrentlyPlaying(null);
    }
  }, [initialData]);

  useEffect(() => {
    if (songs.length > 0) {
      console.log('Filtering songs:', songs.length);
      const filtered = filterSongs(songs, selectedFilter);
      setFilteredSongs(filtered);
      setDisplayedSongs(filtered);
    }
  }, [songs, selectedFilter, englishOnly]);

  const resetAudioStates = () => {
    if (audio) {
      audio.pause();
      audio.src = '';
    }
    setAudio(null);
    setCurrentlyPlaying(null);
    
    // Also cancel any loading audio
    if (loadingAudioRef.current) {
      loadingAudioRef.current.pause();
      loadingAudioRef.current.src = '';
      loadingAudioRef.current = null;
    }
    setLoadingSong(null);
  };

    const handlePlayPreview = async (song) => {
      if (!song?.preview_url) return;

      if (currentlyPlaying?.id === song.id && audio) {
        // If the same song is playing, pause it
        audio.pause();
        setCurrentlyPlaying(null);
        setAudio(null);
      } else {
        resetAudioStates();
        setLoadingSong(song.id);
        
        const newAudio = new Audio(song.preview_url);
        loadingAudioRef.current = newAudio;
        
        try {
          await new Promise((resolve, reject) => {
            newAudio.addEventListener('canplaythrough', resolve, { once: true });
            newAudio.addEventListener('error', reject, { once: true });
            newAudio.load();
          });

          // Check if this is still the audio we want to play
          if (loadingAudioRef.current === newAudio) {
            await newAudio.play();
            setAudio(newAudio);
            setCurrentlyPlaying(song);
            loadingAudioRef.current = null;
            setLoadingSong(null); // Added this line to clear loading state after successful play
          } else {
            // Another song was selected, cleanup this audio
            newAudio.pause();
            newAudio.src = '';
          }
        } catch (error) {
          console.error('Error loading audio:', error);
          setLoadingSong(null); // Clear loading state on error
        } finally {
          if (loadingAudioRef.current === newAudio) {
            loadingAudioRef.current = null;
          }
        }

        newAudio.addEventListener('ended', () => {
          setCurrentlyPlaying(null);
          setAudio(null);
        });
      }
    };
    
    const handleShowSimilar = async (song) => {
        // Reset states
        setSongs([]);
        setDisplayedSongs([]);
        setFilteredSongs([]);
        setHasMinSongs(false);
        setIsLoading(true);
        setIsError(false);
        setErrorMessage('');
        setCurrentReference(song);
        
        if (audio) {
          audio.pause();
          setAudio(null);
          setCurrentlyPlaying(null);
        }

        const controller = new AbortController();
        streamRef.current = controller;

        try {
          const requestBody = {
            song_id: song.id,
            isrc: song.isrc,
            start_year: initialData.startYear,
            end_year: initialData.endYear,
          };

          const response = await fetch(`${BACKEND}/similar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/x-ndjson'
            },
            body: JSON.stringify(requestBody),
            signal: controller.signal
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch songs: ${response.status}`);
          }

          const reader = response.body.getReader();
          let buffer = '';
          const decoder = new TextDecoder();

          while (true) {
            const { value, done } = await reader.read();
            
            if (done) break;

            buffer += decoder.decode(value, { stream: true });
            const lines = buffer.split('\n');
            buffer = lines.pop() || '';

            const newSongs = [];
            for (const line of lines) {
              if (!line.trim()) continue;
              
              try {
                const song = JSON.parse(line);
                newSongs.push(song);
              } catch (error) {
                console.error('Error parsing song:', error);
              }
            }
              
            if (newSongs.length > 0) {
              setSongs(prevSongs => {
                const updatedSongs = [...prevSongs, ...newSongs];
                if (!hasMinSongs && updatedSongs.length >= MIN_SONGS_REQUIRED) {
                  setHasMinSongs(true);
                  setShowVideo(false);
                }
                return updatedSongs;
              });
            }
          }

        } catch (error) {
          if (error.name === 'AbortError') {
            console.log('Stream aborted');
          } else {
            console.error('Failed to fetch similar songs:', error);
            setIsError(true);
            setErrorMessage(error.message);
          }
        } finally {
          setIsLoading(false);
        }
      };
  
  useEffect(() => {
    console.log(showVideo)
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.4;
    }
  }, [showVideo]);

    const getButtonIcon = (song) => {
      if (loadingSong === song?.id) {
        return <Loader className="play-icon animate-spin" />;
      }
      if (currentlyPlaying?.id === song?.id && audio) {
        return <Pause className="play-icon" />;
      }
      return <Play className="play-icon" />;
    };
    
    const drawWaveform = (canvas, data) => {
      if (!canvas) return;
      const ctx = canvas.getContext('2d');
      const { width, height } = canvas.getBoundingClientRect();

      canvas.width = width;
      canvas.height = height;

      const centerY = height / 2;
      const barWidth = 2;
      const barGap = 1;
      const totalBars = Math.floor(width / (barWidth + barGap));
      const samplesPerBar = Math.floor(data.length / totalBars);

      // Clear previous content
      ctx.clearRect(0, 0, width, height);
      
      // Draw background
      ctx.fillStyle = 'rgba(42, 30, 20, 0.9)';
      ctx.fillRect(0, 0, width, height);

      // Draw waveform bars with updated styling
      ctx.fillStyle = 'rgba(255, 240, 220, 0.6)'; // Increased opacity
      
      for (let i = 0; i < totalBars; i++) {
        const startSample = i * samplesPerBar;
        const endSample = startSample + samplesPerBar;
        let sum = 0;
        let max = 0;
        
        // Find max amplitude in this segment
        for (let j = startSample; j < endSample; j++) {
          const value = Math.abs(data[j] || 0);
          sum += value;
          max = Math.max(max, value);
        }
        
        // Use max amplitude for more dynamic visualization
        const amplitude = max;
        const barHeight = Math.max(2, amplitude * height * 0.8); // Minimum height of 2px
        const x = i * (barWidth + barGap);
        
        ctx.fillRect(x, centerY - barHeight / 2, barWidth, barHeight);
      }
    };

      const handleAudioLoaded = async (audioElement, canvasElement) => {
        try {
          const audioContext = new AudioContext();
          const response = await fetch(audioElement.src);
          const arrayBuffer = await response.arrayBuffer();
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
          const channelData = audioBuffer.getChannelData(0);
          drawWaveform(canvasElement, channelData);
        } catch (error) {
          console.error('Error loading audio data:', error);
        }
      };
    
    const ReferenceSong = ({ song, audio, currentlyPlaying, loadingSong, handlePlayPreview, getButtonIcon, drawWaveform }) => {
      const [waveformLoaded, setWaveformLoaded] = useState(false);
      const [progress, setProgress] = useState(0);
      const [isDragging, setIsDragging] = useState(false);
      const [wasPlaying, setWasPlaying] = useState(false);
      const waveformRef = useRef(null);

      useEffect(() => {
        if (song?.preview_url && !waveformLoaded) {
          loadWaveform();
        }
      }, [song, waveformLoaded]);

      useEffect(() => {
        if (audio && currentlyPlaying?.id === song.id && !isDragging) {
          const updateProgress = () => {
            requestAnimationFrame(() => {
              setProgress((audio.currentTime / audio.duration) * 100);
            });
          };
          audio.addEventListener('timeupdate', updateProgress);
          return () => audio.removeEventListener('timeupdate', updateProgress);
        }
      }, [audio, currentlyPlaying, isDragging]);

      const loadWaveform = async () => {
        try {
          const audioContext = new AudioContext();
          const response = await fetch(song.preview_url);
          const arrayBuffer = await response.arrayBuffer();
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
          if (waveformRef.current) {
            drawWaveform(waveformRef.current, audioBuffer.getChannelData(0));
            setWaveformLoaded(true);
          }
        } catch (error) {
          console.error('Error loading waveform:', error);
        }
      };

      const handleMouseDown = (e) => {
        if (audio && currentlyPlaying?.id === song.id) {
          setIsDragging(true);
          setWasPlaying(!audio.paused);
          audio.pause();
          handleScrub(e);
        }
      };

      const handleMouseMove = (e) => {
        if (isDragging) {
          handleScrub(e);
        }
      };

      const handleMouseUp = () => {
        if (isDragging) {
          setIsDragging(false);
          if (wasPlaying) {
            audio.play();
          }
        }
      };

      const handleScrub = (e) => {
        const rect = waveformRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const progress = Math.max(0, Math.min(1, x / rect.width));
        if (audio) {
          audio.currentTime = progress * audio.duration;
        }
        setProgress(progress * 100);
      };

      useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
        };
      }, [isDragging, wasPlaying]);

      useEffect(() => {
        if (!currentlyPlaying) {
          setProgress(0);
        }
      }, [currentlyPlaying]);

      return (
        <div className="reference-song">
          <div className="reference-artwork">
            <img src={song.artwork_url} alt={song.title} className="reference-image" />
            <button
              className={`play-button ${currentlyPlaying?.id === song.id ? 'playing' : ''} 
                         ${loadingSong === song.id ? 'loading' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                handlePlayPreview(song);
              }}
              disabled={!song.preview_url || loadingSong === song.id}
            >
              {getButtonIcon(song)}
            </button>
          </div>
          <div className="reference-info">
            <div className="reference-song-title">{song.title}</div>
            <div className="reference-artist">{song.artist}</div>
            <div className="reference-album">{song.album}</div>
            <div className="waveform-container" onMouseDown={handleMouseDown}>
              <canvas ref={waveformRef} className="waveform-canvas" />
              <div
                className="waveform-progress"
                style={{
                  width: `${progress}%`,
                  transition: isDragging ? 'none' : 'width 0.1s linear'
                }}
              />
            </div>
          </div>
        </div>
      );
    };

    const SongItem = ({ song, audio, currentlyPlaying, loadingSong, handlePlayPreview, getButtonIcon, drawWaveform }) => {
      const [waveformLoaded, setWaveformLoaded] = useState(false);
      const [progress, setProgress] = useState(0);
      const [isDragging, setIsDragging] = useState(false);
      const [wasPlaying, setWasPlaying] = useState(false);
      const [expanded, setExpanded] = useState(false);
      const waveformRef = useRef(null);

      useEffect(() => {
        if (currentlyPlaying?.id === song.id && !waveformLoaded) {
          loadWaveform();
        }
      }, [currentlyPlaying]);
        
        useEffect(() => {
            if (audio && currentlyPlaying?.id === song.id && !isDragging) {
              const updateProgress = () => {
                requestAnimationFrame(() => {
                  setProgress((audio.currentTime / audio.duration) * 100);
                });
              };
              audio.addEventListener('timeupdate', updateProgress);
              return () => audio.removeEventListener('timeupdate', updateProgress);
            }
          }, [audio, currentlyPlaying, song.id, isDragging]);

      const loadWaveform = async () => {
        try {
          const audioContext = new AudioContext();
          const response = await fetch(song.preview_url);
          const arrayBuffer = await response.arrayBuffer();
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
          if (waveformRef.current) {
            drawWaveform(waveformRef.current, audioBuffer.getChannelData(0));
            setWaveformLoaded(true);
          }
        } catch (error) {
          console.error('Error loading waveform:', error);
        }
      };
        
        const handleMouseDown = (e) => {
          if (audio && currentlyPlaying?.id === song.id) {
            setIsDragging(true);
            setWasPlaying(!audio.paused);
            audio.pause();
            handleScrub(e);
          }
        };

        const handleMouseMove = (e) => {
          if (isDragging) {
            handleScrub(e);
          }
        };

        const handleMouseUp = () => {
          if (isDragging) {
            setIsDragging(false);
            if (wasPlaying) {
              audio.play();
            }
          }
        };
        
        const handleScrub = (e) => {
            const rect = waveformRef.current.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const progress = Math.max(0, Math.min(1, x / rect.width));
            audio.currentTime = progress * audio.duration;
            setProgress(progress * 100);
          };

          useEffect(() => {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
            return () => {
              document.removeEventListener('mousemove', handleMouseMove);
              document.removeEventListener('mouseup', handleMouseUp);
            };
          }, [isDragging, wasPlaying]);

      const handlePlayClick = async (e) => {
        e.stopPropagation();
        await handlePlayPreview(song);
      };

        return (
          <div className={`result-item ${(currentlyPlaying?.id === song.id) ? 'expanded' : ''}`}>
            <div className="result-artwork">
              <img src={song.artwork_url} alt={song.title} className="result-image" />
              <button
                className={`play-button ${currentlyPlaying?.id === song.id ? 'playing' : ''} 
                           ${loadingSong === song.id ? 'loading' : ''}`}
                onClick={handlePlayClick}
                disabled={!song.preview_url || loadingSong === song.id}
              >
                {getButtonIcon(song)}
              </button>
            </div>
            <div className="result-info">
              <div className="result-title">{song.title}</div>
              <div className="result-artist">{song.artist}</div>
              <div className="result-album">{song.album} • {song.year} • {song.stream_count}</div>
              {currentlyPlaying?.id === song.id && (
                <div className="waveform-container" onMouseDown={handleMouseDown}>
                  <canvas ref={waveformRef} className="waveform-canvas" />
                  <div
                    className="waveform-progress"
                    style={{
                      width: `${progress}%`,
                      transition: isDragging ? 'none' : 'width 0.1s linear'
                    }}
                  />
                </div>
              )}
            </div>
            <button
              className="show-similar-button"
              onClick={() => handleShowSimilar(song)}
            >
              <Search className="show-similar-icon" />
              Show Similar
            </button>
          </div>
        );
      };

    const handleBack = () => {
      resetAudioStates();
      if (streamRef.current) {
        streamRef.current.abort();
      }
      setShowVideo(false);
      setHasMinSongs(false);
      setCurrentReference(null);
      setSongs([]);
      setDisplayedSongs([]);
      setFilteredSongs([]);
      onBack();
    };
    
    useEffect(() => {
      if (songs.length >= MIN_SONGS_REQUIRED) {
        setHasMinSongs(true);
      }
    }, [songs]);

  if (isError) {
    return (
      <div className="results-page">
        <div className="back-button" onClick={onBack}>
          <ArrowLeft className="back-icon" />
          Back
        </div>
        <div className="error-container">
          <div className="error-message">{errorMessage}</div>
          <button onClick={() => setIsError(false)} className="retry-button">
            Retry
          </button>
        </div>
      </div>
    );
  }

    if (showVideo) {
      return <LoadingTransition onVideoEnd={handleVideoEnd} />;
    }

  if (!hasMinSongs) {
    return (
      <div className="results-page">
        <div className="back-button" onClick={onBack}>
          <ArrowLeft className="back-icon" />
          Back
        </div>
        <div className="loading-container">
          <Loader className="loading-icon animate-spin" />
          <div className="loading-text">Finding similar songs...</div>
        </div>
      </div>
    );
  }

    return (
      <div className="results-page">
        <div className="back-button" onClick={handleBack}>
          <ArrowLeft className="back-icon" />
          Back
        </div>
            
            {currentReference && (
              <div className="reference-container">
                <div className="reference-header">
                  <div className="reference-title">Showing songs similar to...</div>
                  <ReferenceSong
                    song={currentReference}
                    audio={audio}
                    currentlyPlaying={currentlyPlaying}
                    loadingSong={loadingSong}
                    handlePlayPreview={handlePlayPreview}
                    getButtonIcon={getButtonIcon}
                    drawWaveform={drawWaveform}
                  />
                </div>
              </div>
            )}

      <div className="results-section">
        <div className="filter-container">
          <FilterSelector
            onChange={setSelectedFilter}
            style={{
              transform: 'translate(-50%, -50%) scale(1)',
              transformOrigin: 'center center'
            }}
          />
        </div>
        <div className="results-header">
          <div>Similar Songs ({filteredSongs.length} {selectedFilter?.id || 'matching'} tracks)</div>
          <div
            className="english-filter"
            onClick={() => setEnglishOnly(!englishOnly)}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              cursor: 'pointer',
              padding: '8px 12px',
              background: 'rgba(52, 40, 30, 0.9)',
              border: '1px solid rgba(255, 240, 220, 0.2)',
              borderRadius: '4px',
              marginLeft: '16px'
            }}
          >
            <div
              className="toggle-switch"
              style={{
                width: '32px',
                height: '16px',
                backgroundColor: englishOnly ? 'rgba(216, 201, 155, 0.8)' : 'rgba(216, 201, 155, 0.2)',
                borderRadius: '8px',
                position: 'relative',
                transition: 'background-color 0.2s'
              }}
            >
              <div
                className="toggle-knob"
                style={{
                  width: '14px',
                  height: '14px',
                  backgroundColor: 'rgb(255, 240, 220)',
                  borderRadius: '50%',
                  position: 'absolute',
                  top: '1px',
                  left: englishOnly ? '17px' : '1px',
                  transition: 'left 0.2s'
                }}
              />
            </div>
            English Only
          </div>
        </div>
          <div className="results-list">
            {displayedSongs.map(song => (
              <SongItem
                key={song.id}
                song={song}
                audio={audio}
                currentlyPlaying={currentlyPlaying}
                loadingSong={loadingSong}
                handlePlayPreview={handlePlayPreview}
                getButtonIcon={getButtonIcon}
                drawWaveform={drawWaveform}
              />
            ))}
          </div>
      </div>
    </div>
  );
};

export default ResultsPage;
